@keyframes fadeIn {
    0% { 
        opacity: 0; 
    }
    100% { 
        opacity: 1; 
    }
}


.home-bg {
    /* Assuming your image is named 'your-image.jpg' and it's inside the 'public' folder */
    background: url('/Users/adityasharma/Documents/React/mayfairrugs/public/Home1.jpeg') no-repeat center center fixed; 
    background-size:100%;
    min-height: 0vh;
    z-index: 1; 
    position: relative;
    color: white;
}
.home-navbar {
    background-color: transparent !important;
    /* ... other styles you might want specific to the Home navbar ... */
}
.home-page-content h1{

    font-size: 64px;
    padding-top: 20%;
    color: white;
    margin-left: 33%;

}
.additional-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px; /* You can adjust this value as per your design preference */
}


.centered-btn {
    padding: 10px 20px;
    background-color: #4A2C2A; /* Color taken from your enquire-button for consistency */
    color: white;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 5%;
}

.centered-btn:hover {
    background-color: #9a4d3f; /* Slightly darker shade for hover, taken from enquire-button */
    transform: scale(1.05); /* Slight zoom effect on hover */
    color: #ffffff;
}
.home-area{
    text-align: center;
    padding: 40px 0;
    margin-top: 10%;
    background-color: white;
}

.home-title {
    font-size: 52px;
    margin-bottom: 20px;
    font-family: Typo Grotesk Thin;
    color: #111111;
}

.home-pics {
    display: flex;
    justify-content: space-between;
}

.home {
    flex: 1;
    margin: 0 10px;
}

.home img.theme-image {
        max-height: 200px; /* Adjust this value as per your requirements */
        width: auto; /* To maintain aspect ratio */
        margin-bottom: 10px; /* Spacing between image and text */
}

.home p {
    margin: 0; /* Remove default margins */
    align-self: center; /* Center-align text */
    position: absolute; /* Positioning at the bottom of the container */
    bottom: 5%; /* Spacing from the bottom */
    color: #111111;
    font-size: 14px;
}
.home {
    
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: flex-start; 
    height: 250px; /* Adjust this value as per your requirements */
    transition: transform 0.3s ease;
}

.home:hover {
    transform: scale(1.05); /* zoom effect on hover */
}
.centered-text {
    padding-top: 5%;
    padding-left: 20%;
    padding-right: 20%;
    font-size: 26px;
    color: whitesmoke;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    /* Animation properties */
    animation-name: fadeIn;
    animation-duration: 2s; /* you can adjust this duration as desired */
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}
.legacy-text {
    margin-left: 12.3%; /* Adjust this value until it's right below the 'v' in 'Weaving' */
}

@media only screen and (max-width: 768px) {

    .home-bg{
        background: url('/Users/adityasharma/Documents/React/mayfairrugs/public/Home1.jpeg') center ; 
        background-size: 170% 35%;
        
        }
    .home-page-content h1 {
        font-size: 32px;
        padding-top: 55%;
        margin-left: 22%;
      
    }

    .home-area {
        margin-top: 45%;
        padding: 20px 0;
       
    }

    .home-title {
        font-size: 32px;
        margin-bottom: 20px;
        font-family: Typo Grotesk Thin;
        color: #111111;

        
    }

    .home-pics {
        flex-direction: column;
    }

    .home {
        flex-direction: column;
        align-items: center;
        padding: 10px 0;
    }
    .home p{
        align-self: auto; 
        text-align: center; 
        position: relative; 
        padding-top: 5px; 
        margin: 0; 
    }

    .centered-btn{

        margin-top: 27%;
    }

    .legacy-text {
        margin-left: 18%;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* Styles for iPad in landscape orientation go here */

    .home-bg {
        background-size: cover; /* Adjust as per your design */
    }

    .home-page-content h1 {
        font-size: 48px; /* Adjust as per your design */
        padding-top: 15%;
        margin-left: 25%; /* Adjust as per your design */
    }

    .home-area {
        margin-top: 20%; /* Adjust as per your design */
    }

    .home-title {
        font-size: 40px; /* Adjust as per your design */
    }

    .home-pics {
        flex-direction: row; /* Adjust as per your design */
    }

}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* Styles for iPad in portrait orientation go here */

    .home-bg {
        background-size: cover; /* Adjust as per your design */
    }

    .home-page-content h1 {
        font-size: 40px; /* Adjust as per your design */
        padding-top: 20%;
        margin-left: 20%; /* Adjust as per your design */
    }

    .home-area {
        margin-top: 30%; /* Adjust as per your design */
    }

    .home-title {
        font-size: 36px; /* Adjust as per your design */
    }

    .home-pics {
        flex-direction: column; /* Adjust as per your design */
    }
}