.about-us-container {
    font-family: Arial, Helvetica, sans-serif; 
    text-align: center;
    background: url('/Users/adityasharma/Documents/React/mayfairrugs/public/carpet-weaver.png') no-repeat center center;
    background-size: cover;
    background-attachment: fixed !important;
    justify-content: center;
    align-items: center;
    height: 270vh; /* Full viewport height */
   position: relative;/* Avoid scroll */
   background-position: center;
}

.about-us-content {
    opacity: 0;  /* Initially set the opacity to 0 */
    transition: opacity 0.5s;
    padding-top: 25%;
    color: wheat;
    max-height: 0;  /* Initially set the max-height to 0 */
    position: relative;
    padding-left: 20%;
    padding-right: 20%;
    transition: max-height 0.5s, opacity 0.5s;
    display: none;
    max-height: none;
    opacity: 1;
}

.about-us-container h1{
    font-style: bold;
    position: relative; /* Absolute positioning */
    padding-top: 20%;
    align-items: center;
    font-size: 120px !important;
    z-index: 10; /* To be on top */
    color: whitesmoke !important;
}

.about-us-container p {
    color: whitesmoke;
    font-size: 32px;
    margin-bottom: 10%;
}
.about-us-container h3{
    color: whitesmoke !important;
    font-weight: 300;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    
    
}
.show-content {
      /* Adjust as necessary */
    display: block;
}
.fade-out {
    opacity: 0 !important; /* Make it fade out */
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}
.dark-background {
    background: linear-gradient(rgba(0, 0, 0, 0.838), rgba(0, 0, 0, 0.7)), 
                url('/Users/adityasharma/Documents/React/mayfairrugs/public/carpet-weaver.png') no-repeat center center;
    background-position: center

    }


@media only screen and (max-width: 768px) { 
    /* For mobile screens */

    .about-us-container {
        height: 700px; /* Let the content decide the height */
        padding: 50px 0; /* Add some vertical spacing */
        background-position: top center;
    }

    .about-us-container h1 {
        font-size: 48px !important; /* Reduce font size for mobile */
        padding-top: 40%; /* Reduce padding */

    }

    .about-us-container p {
        font-size: 18px; /* Reduce font size */
        margin-bottom: 5%;
    }

    .about-us-content {
        padding-top: 10%; /* Reduce padding */
        padding-left: 5%;
        padding-right: 5%;
        display: none;  /* Initially hide the content */
        opacity: 1;  /* We'll just use display to control visibility */
        max-height: none;
        
    }
    .show-content{
        display: block;
    }
    .fade-out{
        display: none;
    }
    
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .about-us-container {
        height: 400px;
        padding: 50px 0; /* Add some vertical spacing */
    }

    .about-us-container h1 {
        font-size: 60px !important; /* Adjust font size */
        padding-top: 10%; /* Adjust padding */
    }

    .about-us-container p {
        font-size: 24px; /* Adjust font size */
        margin-bottom: 5%;
    }

    .about-us-content {
        padding-top: 10%; /* Adjust padding */
        padding-left: 10%;
        padding-right: 10%;
    }
}

/* For iPad in landscape orientation */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .about-us-container {
        height: 300px;
        padding: 50px 0; /* Add some vertical spacing */
    }

    .about-us-container h1 {
        font-size: 60px !important; /* Adjust font size */
        padding-top: 5%; /* Adjust padding */
    }

    .about-us-container p {
        font-size: 24px; /* Adjust font size */
        margin-bottom: 5%;
    }

    .about-us-content {
        padding-top: 10%; /* Adjust padding */
        padding-left: 10%;
        padding-right: 10%;
    }
}