.themes-container {
    display: flex;
    flex-wrap: wrap;
}

.theme {
    width: 100%; /* spans full width of the viewport */
    height: 100vh; /* full viewport height */
    background-size: cover;
    background-position: center;
    position: relative;
}

.theme-name {
    position: absolute;
    top: 50%; 
    left: 10%;
    transform: translateY(-50%); /* vertically center the text */
    color: wheat;
    font-size: 5.5em;
    font-family: 'Trebuchet MS', sans-serif; /* Using 'Trebuchet MS' and a fallback font */
    text-decoration: none;
    transition: transform 0.3s ease; /* Smooth transition for the hover effect */
}

.theme-name:hover {
    transform: translateY(-50%) scale(1.1); /* It scales up to 110% of its original size on hover */
    color:aliceblue;
}
@media only screen and (min-width: 768px) {
    .theme {
        height: 100vh;
    }

    .theme-name {
        font-size: 5.5em; /* restore the original font size */
    }
}
/* iPad Landscape */
@media (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {

    .theme {
        height: 75vh; /* Adjust the height as needed */
    }

    .theme-name {
        font-size: 4em; /* Adjust the font size as needed */
        left: 5%; /* Adjust the position as needed */
    }
}

/* iPad Portrait */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

    .theme {
        height: 75vh; /* Adjust the height as needed */
    }

    .theme-name {
        font-size: 3.5em; /* Adjust the font size as needed */
        left: 5%; /* Adjust the position as needed */
    }
}
