.page-footer {
    background: #1f1e1e;
    color: #fff;
    padding: 75px 0 0;
    display: flex;
    justify-content: space-between;
    height: 400px;
}

.footer-section {
    flex: 1;
    padding: 0 20px;
}
.footer-section h1{
    font-family: 'Marcellus SC';
    font-size: 42px;
    text-transform: uppercase;
    margin-bottom: 12%;

}
.footer-section p{
    color: #7f7f7f;
}

.footer-links {
    list-style: none;
    padding: 0;
    margin: 30%;
    margin-left: 35%;
    color: #fff;
    text-decoration: none;
    font-weight: bold;      /* Make the links bold */
    font-size: 18px; 
}

.footer-links li {
    margin-bottom: 15px;
}

.footer-links a {
    color: #fff;
    text-decoration: none;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #502c2c;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.footer-btn:hover {
    background-color: #9a4d3f;
    color: #fff;
}
.footer-section-right{
   
    margin-right: 5%;
    
}
@media (max-width: 768px) { /* Mobile view adjustments */

    .page-footer {
        flex-direction: column; 
        height: auto;          
        padding: 20px;  /* Reduced padding for compact look */
    }

    .footer-section, 
    .footer-section-right {
        width: 100%;  
        padding: 10px; 
        text-align: center; 
        margin-bottom: 20px; /* Adding space between sections for clearer separation */
    }

    .footer-links {
        margin: 10% 0; /* Adjusting margin */
        padding-left: 0; /* Ensures no extra padding on the left */
    }

    .footer-section h1 {
        font-size: 24px; /* Making the heading smaller */
        margin-bottom: 8%; /* Adjust margin bottom */
    }

    .footer-links li {
        text-align: center; 
        margin-bottom: 10px; /* Adding some space between links */
    }

    .footer-btn {
        display: block;  
        margin: 10px auto; /* Center the button */
        font-size: 16px; /* Making the button text a bit smaller */
    }

    .footer-section-right {
        margin-right: 0; 
    }

}
/* iPad Landscape */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .page-footer {
        padding: 50px 0;  /* Adjusted padding */
    }

    .footer-section, 
    .footer-section-right {
        width: 45%;  /* Adjusted width for landscape view */
        padding: 20px; 
        text-align: left; 
    }

    .footer-links {
        margin: 10% 0; 
        padding-left: 0; 
    }

    .footer-section h1 {
        font-size: 32px;  /* Adjusted font size */
        margin-bottom: 5%; 
    }

    .footer-links li {
        margin-bottom: 10px; 
    }

    .footer-btn {
        font-size: 18px;  
    }
}

/* iPad Portrait */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {

    .page-footer {
        flex-direction: column;
        padding: 20px;  /* Adjusted padding */
    }

    .footer-section, 
    .footer-section-right {
        width: 100%;  
        padding: 10px; 
        text-align: center; 
        margin-bottom: 20px; 
    }

    .footer-links {
        margin: 10% 0; 
        padding-left: 0; 
    }

    .footer-section h1 {
        font-size: 28px;  /* Adjusted font size */
        margin-bottom: 5%; 
    }

    .footer-links li {
        text-align: center; 
        margin-bottom: 10px; 
    }

    .footer-btn {
        font-size: 16px;  
    }
}
