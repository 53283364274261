.custom-brand {
    font-family: 'Marcellus SC';
    text-transform: uppercase;
    font-weight: 500;
    font-size: 42px !important;
    margin-bottom: 0.05;
    display: block;
    color: white;
    margin-left: 0;
    
}
.container-fluid{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-links {
    display: flex;
    justify-content: space-evenly;
    width: 100%; /* Use the entire width of the parent container */
    margin-top: 1rem;
    font-size: 1px;
}

.navbar {
    position: absolute !important;
    background-color:#502c2c !important;
    transition: background-color 0.3s; /* Smooth transition */
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    flex-wrap: wrap; 
}
.navbar:hover{
    background-color: #B35948 !important;
}
/* Default Navbar Links and Brand Colors */
.navbar .nav-link, .navbar .custom-brand {
    color: white; /* Set default color to #CB9489 */
    transition: color 0.3s, font-size 0.3s; /* Add font-size transition */
    font-size: 20px;
     
}

/* Individual Link Hover Styles */
.navbar .nav-link:hover, .navbar .custom-brand:hover {
    color: #774A3F ; /* Change color to #B35948 */
    font-size: 22px; /* Increase font size to 32px */
}
.active-nav-link {
    font-weight: bold !important;
    font-size: 22px !important;
}

.navbar .navbar-nav .nav-item .nav-link.active, 
.navbar .navbar-nav .nav-item .nav-link.show {
    color: white!important;
    font-weight: bold !important;
    font-size: 22px !important;
    font: Helvetica Neue !important;

}
@media (max-width: 768px) {   /* This breakpoint targets screens smaller than 768px */

    .custom-brand {
        font-size: 30px; /* Smaller font size */
        text-align: center; /* Center the brand name */
    }

    .navbar {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align items */
    }

    .custom-links {
        flex-direction: column; /* Links should be stacked vertically */
        align-items: center; /* Center align links */
    }

    .navbar .nav-link {
        font-size: 18px; /* Smaller font for mobile */
        margin-bottom: 10px; /* Some spacing between links */
    }

    .navbar .nav-link:hover, .navbar .custom-brand:hover {
        font-size: 20px; /* Smaller increase in size on hover */
    }

    .navbar .navbar-nav .nav-item .nav-link.active, 
    .navbar .navbar-nav .nav-item .nav-link.show {
        font-size: 20px; /* Smaller font size for active state */
    }

    /* Hide the toggle button on mobile if not used */
    .navbar-toggler {
        display: none;
        
    }

}