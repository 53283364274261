.products-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;  /* Aligns the children to the left */
  gap: 20px; /* This provides space between the child elements. Adjust as needed */
  width: 100%;
  
}
.product-description, .dropdown, .product-detail {
  display: flex;
  flex-direction: column;
  align-items: center;  /* This will center the content inside */
  width: 100%;  /* Takes the full width of its parent container */
}
.theme-section {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  
}
.product-item {
  
  padding: 40px;
  box-sizing: border-box;
  
  
}
.product-item p{
  
  color: #1f1e1e;
  font-size: 16px;
  font-weight: 500;
  line-height: 3;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.product-item img {
  width: 350px;  
  height: 450px;  
  
}
.blurred {
    filter: blur(5px);
    pointer-events: none; 
  }
  
  .enlarged {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; 
    background-color: rgb(255, 255, 255); 
    display: flex;
    align-items: center;
    width: max-content;
    width: 85%;
    height: 90%;
    cursor:auto;
    opacity: 1;
  }
  .enlarged-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);  /* semi-transparent black */
    display: flex;
    align-items: center;
    justify-content: center;
}

  .enlarged img {
    max-width: 70%;
    max-height: 70%;
    display: block;
    margin: 0 auto;
    transition: transform 0.3s; /* Smooth zooming effect */
    position: relative;
    cursor: zoom-in; 
}
  
  .enlarged p {
    text-align: center;
    
  }
  
  /* Your existing styles for products can be adjusted here. For simplicity, here's a basic one. */
  img {
    max-width: 100%;
    height: auto;
  }
  .theme-section h2 {
    padding-top: 10%;
    font-family: 'Helvetica', sans-serif;
    color: #3E211D;
    font-size: 54px;
    margin-bottom: 20px;
    text-align: left;
    font-weight: bold;
    padding-left: 1%;
}
.theme-section h3{
  font-family: 'Helvetica', sans-serif;
  color: #111111;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: left;
  padding-left: 1%;
}

.products-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
 padding-left: 3%;
}


.enlarged button {
  font-size: 1.5rem; /* Increase the arrow size */
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 20px;  /* Space around the arrows, adjust as needed */
}

.zoom-lens {
  position: absolute;
  width: 200px; /* Adjust based on desired lens size */
  height: 200px; /* Adjust based on desired lens size */
  border: 1px solid #d4d4d4;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: zoom-in;
  border-radius: 50%;
  display: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  pointer-events: none;

}

.zoomable{
  position: relative; /* Ensure it's positioned so z-index takes effect */
  z-index: 900;
}
.arrow-classname {
  z-index: 1100; /* This value should be higher than the z-index of the zoom lens and zoom result, assuming they have any. */
}

.product-description {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align content within product-description */
  padding-right: 1%;
}
.product-description h2{
    font-size: 32px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-right: 15px;
    margin-bottom: 30px;
    position: relative;
    top: 0%;
    padding-right: 1%;
    font-family:'Franklin Gothic Medium', Arial, sans-serif;
    
}
.product-description h3{
  font-size: 16px;
  font-weight: 300;
  color: black;
  letter-spacing: 3px;
  margin-right: 15px;
  margin-bottom: 30px;
  position: relative;
  padding: 1%;

}
.dropdown {

  width: 100px; /* Define a specific width */
  height: 100px; /* Make the height same as width for square shape */
  text-align: center;
  background-color: #fff;
  border: 1px solid #b7b7b7;
  border-radius: 3px;

}
.product-detail {
  text-align: center; /* Center align content within product-detail */

  /* Your existing styles */
  font-size: 15px;
  letter-spacing: 1px;
  margin-right: 15px;
  margin-bottom: 30px;

 
}

.zoom-section {
  /* ... your existing styles for zoom-section ... */
  display: flex;
  align-items: center; /* vertically center items */
  justify-content: space-between; /* maximize space between items */
}

.image-container {
  display: flex;
  align-items: center; /* vertically center items inside the container */
}

.image-container button {
  /* ... any specific styles for the arrow buttons ... */
  height: 40px; /* adjust this value based on your design requirements */
}
/* Styling for the entire page */
body {
  font-family: 'Helvetica', sans-serif;
  margin: 0;
  padding: 0;
  background: linear-gradient(to right, #f2f4f6, #e6ebee); /* subtle gradient */
}

/* Default styling for all product items */
.product-item {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1); /* subtle shadow */
  transition: transform 0.3s ease; /* smooth hover effect */
}

.product-item:hover {
  transform: scale(1.03); /* subtle zoom on hover */
}
/* Container for the tiles */
.product-details-tiles {
  display: flex;
  justify-content: space-between; /* space out the tiles */
  gap: 10px; /* provides spacing between tiles */
}

/* Style for each tile */
.detail-tile {
    display: inline-block;
    padding: 5px 15px; /* Reduced top/bottom padding for a more rectangular appearance */
    background: #f8f6f3;
    margin: 0 15px 15px 0;
    border: 1px solid #e0e0e0; /* Optional: Added a border for better visual distinction */
    border-radius: 4px; /* Optional: Slightly rounded corners for a softer appearance */
    text-align: center;
}


/* Optional: Add some hover effects for better UX */
.detail-tile:hover {
  background-color: #e0e0e0; /* darken the tile a bit on hover */
  
}

/* Add some space between the header and the content of each tile */
.detail-tile h4 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px; 
}
.detail-tile p{
  font-size: 10px;
}
.enquire-button {
  display: inline-block;
  background-color: #502c2c;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 15px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.enquire-button:hover {
  background-color: #9a4d3f; /* Slightly darker shade for hover */
  transform: scale(1.05); /* Slight zoom effect on hover */
  color: white;
}

.enquire-button:focus {
  outline: none;
}

.product-description select {
  /* ... all the other styles ... */
  width: 110px; /* make it a bit wider than its container */
  border-radius: 3px;
  margin: 0; /* remove any margin */
}
/* Style for the dropdown container */
.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 120px;
  overflow: hidden;
  border: 1px solid #b7b7b7;
  border-radius: 3px;
}

/* Style for the select box inside the container */
.custom-dropdown select {
  width: 120%; /* This ensures the default dropdown arrow is pushed out of view */
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  height: 30px;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
}

/* Custom dropdown arrow using pseudo-elements */
.custom-dropdown::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust this if needed */
  transform: translateY(-50%);
  pointer-events: none; /* This ensures the dropdown works when you click on the arrow */
}
/* Styles for the close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: #f3f3f3;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
  transition: background-color 0.2s ease-in-out;
  z-index: 3000;  /* Ensure it's on top */
  color: #1f1e1e;
  
}

.close-btn:hover {
  background-color: wheat /* Slightly darker shade for hover */
}
.product-description p {
  font-weight: bold;
}


.product-detail p {
  color: #000;  
  font-weight: 400;
}
@media only screen and (max-width: 768px) {

  .products-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;  /* Aligns the children to the left */
    gap: 20px; /* This provides space between the child elements. Adjust as needed */
    width: 100%;
    
  }
  .enlarged {
      width: 95%; /* Slightly more width on mobile screens */
      height: 80%; /* Adjust as per your preference */
  }

  .enlarged img {
      max-width: 85%; /* Slightly smaller on mobile */
      max-height: 60%; /* Adjust according to your needs */
  }
}

/* ... Your existing CSS ... */

@media only screen and (max-width: 768px) {
  .enlarged {
      width: 95%; /* Increase width */
      height: 90%; /* Increase height */
      flex-direction: column; /* Stack children vertically */
      align-items: center; /* Center items horizontally */
      justify-content: space-around; /* Distribute space evenly */
      padding: 0%; 
  }

  .product-description {
      width: 100%;
      text-align: center; /* Center content */
      order: 1; /* Ensures it's the first child in .enlarged */
      margin-bottom: 20px; /* Add some spacing below */
      margin: 15px;
      margin-left: 10%;
  }
  .product-description h2{
    margin-left: 2%;
  }
  .image-container {
      width: 100%; /* Full width */
      text-align: center; /* Center content */
      order: 2; /* Ensures it's the second child in .enlarged */
      gap: 10px; /* Spacing */
  }

  .enlarged img {
      max-width: 50%; /* Reduce image size */
      max-height: 50%; /* Reduce image height */
      margin: 5px 26px; /* Center the image */
  }

  .product-detail p {
    display: none; /* Hide the paragraphs/text */
    
}

.product-detail .enquire-button {
  display: inline-block; /* Adjust the display property */
  margin-right: 0%; /* Add some space from the right, adjust as needed */
  margin-left: -4%;
}
.product-detail{
  text-align: right;
}


  .image-container button {
      font-size: 1rem; /* Adjust button size if needed */
      
  }
  .product-details-tiles {
    gap: 2px; /* Reducing the gap if needed */
}

.detail-tile {
    padding: 3px 10px; /* Reduce padding to adjust height */
    font-size: 12px; /* Adjusting the font size if needed */
}

.detail-tile h4 {
    margin-bottom: 5px; /* Reduce margin */
    font-size: 12px; /* Adjust font size if needed */
}

.detail-tile p {
    font-size: 8px; /* Reduce font size */
}
.zoom-lens {
  display: none !important; /* Ensure that it overrides any other styles */
}

.zoomable:hover .zoom-lens,
.zoomable:focus .zoom-lens {
  display: none !important; /* Ensure that hover/focus doesn't activate it */
}

.zoomable {
  cursor: default !important; /* Removes the zoom-in cursor style */
}

.custom-dropdown select{
  color: #111111;
}
.theme-section, .products-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align on mobile */
  gap: 20px; /* Adjust the space as needed */
  width: 100%;
}

.theme-section {
  padding-top: 60px; /* Adding padding to prevent hiding behind the navbar. Adjust the value based on the height of your navbar. */
}
.custom-dropdown {
  margin-right: 8%;
}



}
